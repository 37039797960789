/**
 * @file
 * Custom scripts for theme.
 */
jQuery(document).ready(function ($) {
  /*
   * Toggle menu display on hover
   *
   * @param trigger
   *   Selector for element that triggers menu display
   * @param content
   *   Menu content selector
   * @param underSelector
   *   Selector for element that must be under the menu
   */
  function menuHover(trigger, content, underSelector) {
    var timer;

    if (underSelector != '') {
      underSelector += ':visible';
    }

    var under = $(underSelector);

    $(trigger).on('mouseover', function () {
      clearTimeout(timer);
      $(content).show();
      under.hide();
    }).on('mouseout', function() {
      timer = setTimeout(function() {
        $(content).hide();
        under.show();
      }, 1000);
    });

    $(content).on('mouseover', function() {
      clearTimeout(timer);
    }).on('mouseout', function() {
      var that = this;
      timer = setTimeout(function() {
        $(that).hide();
        under.show();
      }, 1000);
    });
  }

  /*
   * We need to scroll up a bit when url fragments are used since our site
   * header is fixed. If we do not scroll up, the header can block part of
   * fragment page section.
   */
  var urlHash = window.location.hash.replace('/', '');
  var $hash = $(urlHash);

  if ($hash.length === 1) {
    // Using setTimeout allows us to override the browser fragment behaviour.
    window.setTimeout(function () {
      $([document.documentElement, document.body]).animate(
        {scrollTop: $hash.offset().top - $('header').height()},
        'fast'
      );
    }, 0);
  } else if (urlHash !== '') {
    /*
     * We need to add the url fragment to login form action in order to
     * ensure that it is applied to the destination page.
     */
    var $loginForm = $('form#user-login-form, form#user-login');

    if ($loginForm.length === 1) {
      var loginAction = $loginForm.attr('action');

      if (loginAction !== '' && loginAction.indexOf('#') === -1) {
        $loginForm.attr('action', loginAction + urlHash);
      }
    }
  }

  // set up main menu item widths
  var maxWidth = Math.max.apply(null, $('.sf-vertical > li').map(function() {
    return $(this).outerWidth(true);
  }).get());

  maxWidth += 20;

  $('.sf-vertical').outerWidth(maxWidth);
  $('.sf-vertical > li').outerWidth(maxWidth);

  $('.sf-vertical > li').on('mouseover', function() {
    $(this).find('> ul').css('left', maxWidth + 'px');
  });

  $('.oa-navbar .pane-superfish-1').hide().css('top', $('#oa_breadcrumb > li:first-child').height());

  // correct menu display for mobile
  $('.dl-menuwrapper').parent().css('top', 0).show();

  // add logo to top of mobile menu
  $('#oa_breadcrumb li:first-child > a:first').clone().prependTo('#rm-dl-menu');

  // move content over when menu displayed
  $('.dl-trigger').click(function() {
    var duration = 200;

    if ($('#rm-dl-menu').css('left') != '0px') {
      var menuSize = $('#rm-dl-menu').width();

      $('#rm-dl-menu').animate({left: 0}, duration);
      $(this).animate({left: menuSize}, duration);
      $('#main').animate({left: menuSize}, duration);
      $('#oa-user-badge').animate({left: menuSize}, duration);
    } else {
      $('#rm-dl-menu').animate({left: '-275px'}, duration);
      $(this).animate({left: 0}, duration);
      $('#main').animate({left: 0}, duration);
      $('#oa-user-badge').animate({left: 0}, duration);
    }
  });

  // add icons to sidebar menu
  $('.menu li.dhtml-folder.expanded').prepend('<i class="fa fa-angle-up folder-control"></i>');
  $('.menu li.dhtml-folder.collapsed').prepend('<i class="fa fa-angle-down folder-control"></i>');

  // toggle sidebar submenu items
  $('.menu li.dhtml-folder i.folder-control').click(function() {
    if ($(this).hasClass('fa-angle-down')) {
      $(this).removeClass('fa-angle-down').addClass('fa-angle-up');
    } else {
      $(this).removeClass('fa-angle-up').addClass('fa-angle-down');
    }

    $(this).siblings().filter('.menu').toggle();
  });

  // set up menu hover on desktop sites
  if ($('.dl-menuwrapper').length == 0) {
    // toggle main menu display
    menuHover('.oa-navbar .oa-site-banner-img', '.oa-navbar .pane-superfish-1', '');

    // toggle user menu display
    menuHover(
      '#user-badge-dropdown .dropdown-toggle',
      '#oa-user-badge .dropdown-menu',
      '#panels-ipe-control-container'
    );
  } else {
    $('#rm-dl-menu').css('top', '-76px');

    var menuItems = $('#rm-dl-menu').find('li:not(.dl-back)');

    // set up nav submenu animation
    menuItems.on('click.dlmenu', function(event) {
      event.stopPropagation();

      $(this).find('> a').css('display', '');
      var submenu = $(this).children('ul.dl-submenu');

      if (submenu.length > 0 ) {
        submenu.css({left: '275px'}).show()
         .animate({'left':'0px'}, 'slow');
      }
    });

    var backLinks = $('#rm-dl-menu').find('li.dl-back');

    backLinks.on('click.dlmenu', function(event) {
      var submenu = $(this).closest('.dl-submenu');

      event.stopPropagation();

      if (submenu.length == 1) {
        submenu.hide();

        var prevSubmenu = submenu.parent().closest('.dl-submenu');

        if (prevSubmenu.length == 1) {
          prevSubmenu.css({left: '-275px'})
           .animate({'left':'0px'}, 'slow');
        } else {
          $(this).parent().siblings().css({left: '-275px', display: ''})
           .animate({'left':'0px'}, 'slow');
        }
      }
    });

    // display menu item for current page
    var url = window.location.pathname;

    if (url != '/') {
      var curLink = $('#dl-menu a[href="' + url + '"]:last').parent().addClass('dl-curpage');
      var clickLinks = new Array();
      var curParent = curLink.parent().parent();

      while (curParent.is('li')) {
        clickLinks.push(curParent);
        curParent = curParent.parent().parent();
      }

      var curClick = clickLinks.pop();

      while (curClick != undefined) {
        curClick.click();
        curClick = clickLinks.pop();
      }
    }
  }

  // add expansion icons to admin menu
  $('#navbar-administration .navbar-handle').each(function() {
    $(this).parent().prepend('<i class="fa fa-angle-down"></i>');
  });

  // set up icon sub-item expansion
  $('#navbar-administration .navbar-box i').on('click', function() {
    $(this).siblings().filter('.navbar-handle').click();

    $('#navbar-administration .navbar-box i').each(function() {
      if ($(this).siblings().filter('.navbar-handle').hasClass('open')) {
        $(this).removeClass('fa-angle-down').addClass('fa-angle-up');
      } else {
        $(this).removeClass('fa-angle-up').addClass('fa-angle-down');
      }
    });
  });

  // toggle admin root menu display
  $('#navbar-administration h3 i').on('click', function() {
    $(this).parent().next().toggle();

    if ($(this).hasClass('fa-angle-down')) {
      $(this).removeClass('fa-angle-down').addClass('fa-angle-up');
    } else {
      $(this).removeClass('fa-angle-up').addClass('fa-angle-down');
    }
  });

  /*
   * We moved this code to the bottom this block to see if it will fix the
   * itermittent fa issue PD-1197.
   */
  $('.oa-navbar .pane-superfish-1 .sf-sub-indicator').replaceWith('<i class="fa fa-angle-right"></i>');
});


(function($) {
  /*
   * Resize IPE dragbar
   */
  Drupal.behaviors.resizeIpeDragbar = {
    attach: function (context) {
      $('.panels-ipe-dragbar', context).each(function() {
        var numOptions = $('li', this).length;

        if ($(this).hasClass('panels-ipe-draghandle')) {
          numOptions += 1;
        }

        var width = 43 * numOptions;

        $(this).width(width);
      });
    }
  };
}) (jQuery);


